<template>
    <ul class="record-list">
        <li class="record-item"
            v-for="item in recordList"
            :key="item.name">
            <h3><i :class="item.icon"></i>{{item.name}}</h3>
            <p>
                <label>当日</label>
                <span>￥{{item.time_data}}</span>
				<!-- valueKbit  过滤-->
            </p>
            <p v-if="timeType < 3">
                <label>当月</label>
                <span>￥{{item.month_data}}</span>
				<!-- valueKbit  过滤-->
            </p>
        </li>
    </ul>
</template>

<script>
	export default {
		name:"revenue",
		props:{
			recordList:{
				type:Array,
				default:() => []
			},
			timeType:{
				type:Number,
				default:1
			},
		},
		data(){
			return {
				chargeRecord:null,
			}
		},
    filters: {
          // 用逗号分隔开
          separateNum(value) {
            let result="";
            if(!value || value == '.00'||value == '0.00'){
              result = "0.00"
            }else{
              value = parseFloat(value)
              result=(value || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            }
            return result
          }
      },
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .record-list{
        display:flex;
        align-items:center;
        justify-content:space-between;
        flex-wrap:wrap;
    }

    .record-item{
        width:334px;
        border-radius:6px;
        background:#F3F3F3;
        margin:20px 20px 0 0;
        padding:20px 20px 10px;
    }

    .record-item:nth-child(even){
        margin:20px 0 0;
    }

    h3{
        font-size:30px;
        color:rgb(51,51,51);
        margin:0 0 10px;
        height:80px;
        line-height:80px;
        display:flex;
        align-items:center;
    }

    i{
        display:inline-block;
        width:56px;
        height:56px;
        background:url('~@/assets/images/cash-icon.png') no-repeat center;
        background-size:56px;
        margin:0 15px 0 0;
    }

    .cash-icon{
        background:url('~@/assets/images/cash-icon.png') no-repeat center;
        background-size:56px;
    }

    .union-pay-icon{
        background:url('~@/assets/images/union-pay-icon.png') no-repeat center;
        background-size:56px;
    }

    .wechat-pay-icon{
        background:url('~@/assets/images/wechat-pay-icon.png') no-repeat center;
        background-size:56px;
    }

    .Alipay-icon{
        background:url('~@/assets/images/Alipay-icon.png') no-repeat center;
        background-size:56px;
    }

    .MTPay-icon{
        background:url('~@/assets/images/MTPay-icon.png') no-repeat center;
        background-size:56px;
    }

    .e-pay-icon{
        background:url('~@/assets/images/e-pay-icon.png') no-repeat center;
        background-size:56px;
    }

    .member-pay-icon{
        background:url('~@/assets/images/member-pay-icon.png') no-repeat center;
        background-size:56px;
    }

    .coupon-pay-icon{
        background:url('~@/assets/images/coupon-pay-icon.png') no-repeat center;
        background-size:56px;
    }

    .bookkeeping-pay-icon{
        background:url('~@/assets/images/bookkeeping-pay-icon.png') no-repeat center;
        background-size:56px;
    }

    .free-pay-icon{
        background:url('~@/assets/images/free-pay-icon.png') no-repeat center;
        background-size:56px;
    }

    p{
        display:flex;
        justify-content:space-between;
        color:#999999;
        line-height:40px;
        height:40px;
        font-size:28px;
    }

    p span{
        color:#333333;
        font-size:30px;
    }
</style>
